import { ComponentProps, useState } from "react";

import { useApiSecretKeyLazyQuery } from "@/apollo/types";
import { ButtonGroup, IconButton } from "@/components/elements/Button";
import { Input } from "@/components/primitives/input";
import cn from "@/helpers/classNames";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { useTimeout } from "@/hooks/useTimeout";
import { CheckIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { Square2StackIcon } from "@heroicons/react/24/outline";

const hiddenValue = new Array(50).fill("x").join("");

export function ApiSecretKeyReveal(props: { apiKeyId: string }) {
  const [getApiKeyWithValue, { data, loading }] = useApiSecretKeyLazyQuery();
  const secretKeyValue = data?.apiSecretKey;

  const [reveal, setReveal] = useState(false);
  const [, copy] = useCopyToClipboard();

  const handleRevealClicked = async () => {
    if (reveal) {
      setReveal(false);
    } else {
      const resp = await getApiKeyWithValue({
        variables: { apiKeyId: props.apiKeyId },
      });
      if (resp.data) {
        setReveal(true);
      }
    }
  };

  return (
    <div className="w-full max-w-96">
      <div className="flex gap-1">
        <div className="grow">
          <Input
            type={reveal ? "text" : "password"}
            value={reveal ? secretKeyValue : hiddenValue}
            readOnly
            size="sm"
            className={cn("w-full text-xs", {
              "text-sm text-muted-foreground": !reveal,
            })}
          />
        </div>
        <ButtonGroup size="sm" className="flex-none gap-1">
          {reveal && secretKeyValue && (
            <CopyIconButton onClick={() => copy(secretKeyValue)} />
          )}
          <IconButton
            icon={reveal ? <EyeSlashIcon /> : <EyeIcon />}
            onClick={() => handleRevealClicked()}
            isLoading={loading}
          />
        </ButtonGroup>
      </div>
    </div>
  );
}

function CopyIconButton(
  props: Omit<ComponentProps<typeof IconButton>, "icon">,
) {
  const [copied, setCopied] = useState(false);

  useTimeout(
    () => {
      setCopied(false);
    },
    copied ? 2000 : null,
  );

  return (
    <IconButton
      {...props}
      onClick={(e) => {
        setCopied(true);
        props.onClick?.(e);
      }}
      icon={copied ? <CheckIcon /> : <Square2StackIcon />}
    />
  );
}
