import { useFlags } from "launchdarkly-react-client-sdk";
import { ComponentProps } from "react";

import { Role } from "@/apollo/types";
import cn from "@/helpers/classNames";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import { useCurrentUser } from "@/providers/UserProvider";
import { useUserRole } from "@/providers/account";
import { LinkWithSlug } from "@/routes";

function MenuItemLink(props: ComponentProps<typeof LinkWithSlug>) {
  return (
    <LinkWithSlug
      {...props}
      className={cn(
        "block rounded px-3 py-1.5 hover:bg-black/10 dark:hover:bg-white/10",
        props.className,
      )}
      getActiveProps={() => ({
        className: "font-semibold bg-black/5 dark:bg-white/5",
      })}
    />
  );
}

function Separator() {
  return <li className="my-2 h-px bg-gray-200 dark:bg-gray-700" />;
}

export function SettingsNav(props: ComponentProps<"nav">) {
  const user = useCurrentUser();
  const userRole = useUserRole();
  const dwh = useDataWarehouseContext();
  const { displayApiKeys } = useFlags();
  return (
    <nav className={cn("text-sm", props.className)}>
      <ul>
        <li>
          <MenuItemLink to="/settings/connections">Connectors</MenuItemLink>
        </li>
        <Separator />
        <li>
          <MenuItemLink to="/settings/user-profile">User Profile</MenuItemLink>
        </li>
        <li>
          <MenuItemLink to="/settings/members">Members</MenuItemLink>
        </li>
        <li>
          <MenuItemLink to="/settings/notifications">
            Notifications
          </MenuItemLink>
        </li>
        {userRole === Role.Admin && (
          <li>
            <MenuItemLink to="/settings/billing">Billing</MenuItemLink>
          </li>
        )}
        <li>
          <MenuItemLink to="/settings/account">Account</MenuItemLink>
        </li>
        <Separator />
        {userRole === Role.Admin && (
          <li>
            <MenuItemLink to="/settings/audit-logs">Audit Logs</MenuItemLink>
          </li>
        )}
        {userRole === Role.Admin && displayApiKeys && (
          <li>
            <MenuItemLink to="/settings/api-keys">API Keys</MenuItemLink>
          </li>
        )}
        <li>
          <MenuItemLink to="/settings/external-tools">
            External Tools
          </MenuItemLink>
        </li>
        <Separator />
        {(dwh.integration.id === "snowflake" ||
          dwh.integration.id === "weld-bigquery" ||
          dwh.integration.id === "bigquery") && (
          <li>
            <MenuItemLink to="/settings/data-warehouse">
              Data Warehouse
            </MenuItemLink>
          </li>
        )}
        <li>
          <MenuItemLink to="/settings/bi-tools">BI Tools</MenuItemLink>
        </li>
        <Separator />
        {user.weldSuperAdmin && (
          <li>
            <MenuItemLink to="/settings/back-office">Back Office</MenuItemLink>
          </li>
        )}
      </ul>
    </nav>
  );
}
