import { useForm } from "react-hook-form";

import { Role } from "@/apollo/types";
import FormTextInput from "@/components/modules/FormFactory/FormTextInput";
import SelectForm from "@/components/modules/FormFactory/SelectForm";

import { userRoleOptions } from "./constants";

export type InvitationFormModel = {
  email: string;
  firstName: string;
  lastName: string;
  role: {
    value: Role;
    label: string;
  };
};

export function useInvitationForm() {
  return useForm<InvitationFormModel>({
    mode: "onChange",
    defaultValues: {
      role: { value: Role.Analyst, label: "Analyst" },
    },
  });
}

export function InvitationForm() {
  return (
    <>
      <FormTextInput
        field={{
          type: "email",
          required: true,
          name: "email",
          label: "Email",
          inputProps: {
            placeholder: "something@domain.com",
            autoFocus: true,
          },
        }}
      />
      <FormTextInput
        field={{
          type: "text",
          required: true,
          name: "firstName",
          label: "First name",
          inputProps: { placeholder: "John" },
        }}
      />
      <FormTextInput
        field={{
          type: "text",
          required: true,
          name: "lastName",
          label: "Last name",
          inputProps: { placeholder: "Doe" },
        }}
      />
      <SelectForm
        field={{
          type: "dropdown",
          dropdownOptions: userRoleOptions,
          label: "Role",
          name: "role",
          required: true,
          placeholder: "Select user role",
        }}
      />
    </>
  );
}
