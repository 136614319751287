import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { reverse, sortBy } from "lodash";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import { CellProps, Column } from "react-table";
import { useNavigateWithSlug } from "routes";

import {
  Role,
  UsersDocument,
  UsersQuery,
  useRemoveUserMutation,
  useUpdateUserAccountRoleMutation,
  useUsersQuery,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import DefaultTable from "@/components/elements/DefaultTable";
import MenuItem from "@/components/elements/MenuItem";
import Tooltip from "@/components/elements/Tooltip";
import {
  ListboxOption,
  ListboxOptions,
} from "@/components/elements/input/ListboxDropdown";
import TableMenu from "@/components/modules/TableMenu";
import { dateFormat, dateTimeFormat } from "@/helpers/dateFormat";
import useAuthorized from "@/hooks/useAuthorized";
import useDeleteItem from "@/hooks/useDeleteItem";
import useLoadingManager from "@/hooks/useLoadingManager";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentUser } from "@/providers/UserProvider";
import { Listbox } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { userRoleOptions } from "./constants";

dayjs.extend(relativeTime);

type Row = UsersQuery["users"][0];

const UsersTable = () => {
  const columns = React.useMemo<Column<Row>[]>(
    () => [
      {
        Header: "Email",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.email}</span>;
        },
        accessor: "email",
      },
      {
        Header: "First name",
        Cell: ({ row }: CellProps<Row>) => {
          if (!row.original.firstName)
            return (
              <span className="text-sm italic text-gray-400">Not provided</span>
            );
          return <span>{row.original.firstName}</span>;
        },
        accessor: "firstName",
      },
      {
        Header: "Last name",
        Cell: ({ row }: CellProps<Row>) => {
          if (!row.original.lastName)
            return (
              <span className="text-sm italic text-gray-400">Not provided</span>
            );
          return <span>{row.original.lastName}</span>;
        },
        accessor: "lastName",
      },
      {
        Header: "Role",
        Cell: UserRoleCell,
        accessor: "role",
      },
      {
        Header: "Created",
        Cell: ({ row }: CellProps<Row>) => (
          <Tooltip
            content={dayjs(row.original.createdAt).format(dateTimeFormat)}
          >
            <div>{dayjs(row.original.createdAt).format(dateFormat)}</div>
          </Tooltip>
        ),
        accessor: "createdAt",
      },
      {
        Header: "Updated",
        Cell: ({ row }: CellProps<Row>) => (
          <Tooltip
            content={dayjs(row.original.updatedAt).format(dateTimeFormat)}
          >
            <div>{dayjs(row.original.updatedAt).format(dateFormat)}</div>
          </Tooltip>
        ),
        accessor: "updatedAt",
      },
      {
        Header: "",
        Cell: ({ row }: CellProps<Row>) => (
          <div className="flex justify-end">
            <UserMenu userId={row.original.id} />
          </div>
        ),
        accessor: "id",
      },
    ],
    [],
  );
  const { data, error, loading } = useUsersQuery();

  const tableData = reverse(sortBy(data?.users, "createdAt")) || [];

  return useLoadingManager({ loading, message: `Loading users` }, () => {
    if (error) {
      return (
        <>
          An error happened while loading the users. Try again or contact admin
          if it persists.
        </>
      );
    }

    return <DefaultTable columns={columns} data={tableData} />;
  });
};

function UserRoleCell({ row }: CellProps<Row>) {
  const canEditRole = useAuthorized(["manage:all"]);
  const toast = useToast();

  const [updateUserAccountRole] = useUpdateUserAccountRoleMutation({
    onCompleted(user) {
      const { firstName, lastName, role } = user.updateUserAccountRole;
      const fullName = `${firstName ?? ""} ${lastName ?? ""}`;
      toast(`${fullName} is now an ${role}`, "", "success");
    },
  });

  const value = row.original.role as Role;

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: "auto",
    modifiers: [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [0, 5] } },
    ],
  });

  const handleSelect = async (role: Role) => {
    await updateUserAccountRole({
      variables: {
        userId: row.original.id,
        role,
      },
    });
  };

  if (!canEditRole) {
    return <span>{value}</span>;
  }
  return (
    <Listbox<"div", Role> value={value} onChange={handleSelect}>
      <Listbox.Button
        ref={setReferenceElement}
        className="flex w-full justify-between text-sm font-normal"
        as={Button}
        variant="ghost"
        size="xs"
        iconRight={<ChevronUpDownIcon className="h-4" />}
      >
        <span>{value}</span>
      </Listbox.Button>
      <ListboxOptions
        className="w-28"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className="px-1 py-1">
          {userRoleOptions.map((option) => (
            <ListboxOption key={option.value} value={option.value}>
              {option.label}
            </ListboxOption>
          ))}
        </div>
      </ListboxOptions>
    </Listbox>
  );
}

const UserMenu = ({ userId }: { userId: string }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const currentUser = useCurrentUser();
  const navigate = useNavigateWithSlug();

  const handleDelete = useDeleteItem({
    title: "user",
    variables: { userId },
    mutation: useRemoveUserMutation,
    refetchQueries: [{ query: UsersDocument }],
  });
  return (
    <>
      <ConfirmDeleteModal
        title="user"
        onConfirm={handleDelete}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
      <TableMenu>
        {currentUser.id === userId && (
          <MenuItem
            text={"Edit"}
            onClick={() => navigate({ to: "settings/user" })}
          />
        )}
        <MenuItem text={"Delete"} onClick={() => setShowConfirmModal(true)} />
      </TableMenu>
    </>
  );
};

export default UsersTable;
