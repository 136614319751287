import { FormProvider, useForm } from "react-hook-form";

import { ApiKeysDocument, useCreateApiKeyMutation } from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import FormTextInput from "@/components/modules/FormFactory/FormTextInput";
import { useToast } from "@/providers/ToastProvider";
import { ComponentProps } from "@tw-classed/react";

export function ApiKeyCreationDialog(props: ComponentProps<typeof Modal>) {
  const toast = useToast();
  const form = useForm<{ name: string }>({
    mode: "onChange",
  });

  const [createApiKey] = useCreateApiKeyMutation({
    onCompleted() {
      toast("Successful", "API key successfully created", "success");
      form.reset();
      props.onClose();
    },
    onError(error) {
      toast(
        "Error creating an API key. Please try again",
        error.message,
        "error",
      );
      props.onClose();
    },
    refetchQueries: [{ query: ApiKeysDocument }],
  });

  const onSubmit = (data: { name: string }) => {
    return createApiKey({
      variables: { input: data },
    });
  };

  return (
    <Modal centerOnScreen={false} {...props}>
      <ModalHeader>
        <div>Create an API key</div>
      </ModalHeader>
      <ModalCloseButton />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalBody>
            <FormTextInput
              field={{
                type: "text",
                required: true,
                name: "name",
                label: "name",
                value: "",
                inputProps: {
                  placeholder: "Production Key",
                  autoFocus: true,
                },
              }}
            />
          </ModalBody>
          <ModalFooter className="flex-row-reverse justify-start gap-4">
            <PrimaryButton
              disabled={!form.formState.isValid}
              isLoading={form.formState.isSubmitting}
              type="submit"
            >
              Create
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </SecondaryButton>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
}
