import { ListSyncsQuery } from "@/apollo/types";
import { useNavigate } from "@tanstack/react-location";

import { ReverseEltJobsCompletionStats } from "./RevEltJobsCompletionStats";
import { DataTable } from "./RevEltSyncsDataTable";
import { RevEltSyncsNotifications } from "./RevEltSyncsNotifications";

const SyncsTable = (props: { syncs: ListSyncsQuery["syncs"] }) => {
  const navigate = useNavigate();
  const onRowClick = (
    row: { id: string },
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (e.metaKey || e.ctrlKey) {
      window.open(`${window.location.href}/${row.id}`);
      return;
    }
    navigate({ to: `./${row.id}` });
  };

  return (
    <div className="flex flex-col gap-8">
      <ReverseEltJobsCompletionStats />
      <RevEltSyncsNotifications syncs={props.syncs} />
      <div className="w-full max-w-screen-xl">
        <DataTable syncs={props.syncs} onRowClick={onRowClick} />
      </div>
    </div>
  );
};

export default SyncsTable;
