import { useFlags } from "launchdarkly-react-client-sdk";
import React, { Suspense } from "react";

import { Role, useApiKeysSuspenseQuery } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { EmptyState } from "@/components/elements/EmptyState";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useUserRole } from "@/providers/account";
import { PlusIcon } from "@heroicons/react/20/solid";

import { SettingsPageLayout } from "../PageLayout";
import { ApiKeyCreationDialog } from "./ApiKeyCreationDialog";
import ApiKeysTable from "./ApiKeysTable";

export default function ApiKeysPageWrapper() {
  const userRole = useUserRole();
  const { displayApiKeys } = useFlags();
  if (userRole !== Role.Admin || !displayApiKeys) {
    return (
      <SettingsPageLayout title="API Keys">
        You don't have access to see API keys.
      </SettingsPageLayout>
    );
  }
  return (
    <ApiKeysPageLayout>
      <Suspense fallback={<LoadingSpinner />}>
        <ApiKeysContent />
      </Suspense>
    </ApiKeysPageLayout>
  );
}

function ApiKeysPageLayout(props: { children: React.ReactNode }) {
  const apiKeyCreationDisclosure = useDisclosure();
  return (
    <>
      <ApiKeyCreationDialog {...apiKeyCreationDisclosure} />
      <SettingsPageLayout
        title="API Keys"
        action={
          <Button
            tabIndex={-1}
            onClick={() => {
              apiKeyCreationDisclosure.onOpen();
            }}
            icon={<PlusIcon className="w-4" />}
            variant="solid"
            colorScheme="primary"
          >
            Create API key
          </Button>
        }
      >
        {props.children}
      </SettingsPageLayout>
    </>
  );
}

function ApiKeysContent() {
  const apiKeyCreationDisclosure = useDisclosure();
  const { data } = useApiKeysSuspenseQuery();
  if (data.apiKeys.length === 0) {
    return (
      <EmptyState
        header="No API keys found"
        description="Create an API key to start using WELD's Open API."
        action={
          <>
            <Button
              onClick={() => {
                apiKeyCreationDisclosure.onOpen();
              }}
              icon={<PlusIcon />}
            >
              Create API key
            </Button>
            <ApiKeyCreationDialog {...apiKeyCreationDisclosure} />
          </>
        }
      />
    );
  }
  return <ApiKeysTable data={data.apiKeys} />;
}
