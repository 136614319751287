import { CellProps, Column, SortByFn } from "react-table";

import { ListSyncsQuery } from "@/apollo/types";
import cronExpressionToDayjs from "@/helpers/cronExpressionToDayjs";

import { ConnectorCell } from "./cells/ConnectorCell";
import { ModelCell } from "./cells/ModelCell";
import { NextSyncCell } from "./cells/NextSyncCell";
import { SyncMenuCell } from "./cells/SyncMenuCell";
import { SyncStatusCell } from "./cells/SyncStatusCell";

export type RowData = ListSyncsQuery["syncs"][0] & {
  modelPath?: string;
};

const getCronExpressionValue = (row: RowData, desc?: boolean) => {
  const maxMinSortValue = desc
    ? Number.MIN_SAFE_INTEGER
    : Number.MAX_SAFE_INTEGER;

  if (row.status === "NOT_STARTED" || row.status === "PAUSED") {
    return maxMinSortValue;
  }
  if (row.orchestrationWorkflow?.id) {
    return cronExpressionToDayjs(
      row.orchestrationWorkflow.cronExpression,
    ).unix();
  }
  if (row.syncInterval) {
    return cronExpressionToDayjs(row.syncInterval).unix();
  }
  return maxMinSortValue;
};

const cronExprSortFn: SortByFn<RowData> = (rowA, rowB, columnId, desc) => {
  return (
    getCronExpressionValue(rowA.original, desc) -
    getCronExpressionValue(rowB.original, desc)
  );
};

const connectorColumnSortFn: SortByFn<RowData> = (
  rowA,
  rowB,
  columnId,
  desc,
) => {
  const baseResult = rowA.original.destinationIntegrationId.localeCompare(
    rowB.original.destinationIntegrationId,
  );
  if (baseResult !== 0) return baseResult;
  const primaryObjectResult = rowA.original.primaryObject.localeCompare(
    rowB.original.primaryObject,
  );
  if (primaryObjectResult !== 0) {
    return primaryObjectResult;
  }
  if (
    rowA.original.config?.destinationSettings &&
    rowB.original.config?.destinationSettings
  ) {
    const aDestinationSettings = JSON.stringify(
      rowA.original.config.destinationSettings,
    );
    const bDestinationSettings = JSON.stringify(
      rowB.original.config.destinationSettings,
    );
    return aDestinationSettings.localeCompare(bDestinationSettings);
  }
  return primaryObjectResult;
};

export const columns: Column<RowData>[] = [
  {
    Header: "Connector",
    Cell: ({ row }) => {
      return <ConnectorCell rEltSync={row.original} />;
    },
    accessor: "destinationIntegrationId",
    sortType: connectorColumnSortFn,
  },
  {
    Header: "Model",
    Cell: ({ row }) => {
      if (!row.original.model) return <div />;
      return (
        <ModelCell
          model={row.original.model}
          modelPath={row.original.modelPath}
        />
      );
    },
    accessor: "modelPath", // This accessor will be used for sorting.
  },
  {
    Header: "Next sync",
    Cell: ({ row, value }: CellProps<RowData, RowData["syncInterval"]>) => {
      return <NextSyncCell row={row.original} syncInterval={value} />;
    },
    accessor: "syncInterval",
    sortType: cronExprSortFn,
  },
  {
    Header: "Status",
    Cell: ({ row }) => <SyncStatusCell sync={row.original} />,
    accessor: "status",
    disableSortBy: true,
  },
  {
    Header: "",
    Cell: ({ row }) => (
      <div className="flex justify-end">
        <SyncMenuCell sync={row.original} />
      </div>
    ),
    width: 20,
    maxWidth: 20,
    accessor: "active",
    disableSortBy: true,
    filter: "exact",
  },
];
