import { Button } from "components/elements/Button";
import { useDisclosure } from "hooks/useDisclosure";
import { ComponentProps } from "react";

import { Heading } from "@/components/elements/Typography";
import { useConnections } from "@/features/connectors";
import { IntegrationLogo } from "@/integrations";
import { CheckIcon } from "@heroicons/react/20/solid";

import { SettingsPageLayout } from "../PageLayout";
import { DbtSetupSlideOver } from "./dbt/DbtSetupSlideOver";

export default function ExternalToolsPage() {
  const connections = useConnections("UtilityConnection");

  const slideOverDisclosure = useDisclosure();
  const hasDbt =
    !connections.loading &&
    !!connections.connections.find((c) => c.integrationId === "dbt-cloud");

  return (
    <SettingsPageLayout title="External Tools">
      <DbtSetupSlideOver
        show={slideOverDisclosure.isOpen}
        onClose={() => {
          slideOverDisclosure.onClose();
        }}
      />
      <div>
        <Heading className="mb-2">dbt Cloud</Heading>
        <p className="mb-4 text-sm text-muted-foreground">
          Connect your dbt Cloud account and set up triggers for dbt jobs to
          start automatically after WELD pipelines finish, ensuring smooth data
          processing without manual steps.
        </p>
        <DBTButton
          onClick={() => {
            slideOverDisclosure.onOpen();
          }}
          disabled={hasDbt}
        >
          Connect dbt Cloud
          {hasDbt && (
            <CheckIcon
              className="ml-3 h-5 w-5 text-green-500 dark:text-green-400"
              aria-hidden="true"
            />
          )}
        </DBTButton>
      </div>
    </SettingsPageLayout>
  );
}

const DBTButton = (props: ComponentProps<typeof Button>) => {
  return (
    <Button
      {...props}
      icon={<IntegrationLogo id="dbt-cloud" />}
      variant="outline"
    />
  );
};
