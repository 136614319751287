import dayjs from "dayjs";

import { useReverseEtlJobStatusQuery } from "@/apollo/types";
import Badge, { BadgeVariant } from "@/components/elements/Badge_Legacy";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { FormatRelativeTime } from "@/components/elements/FormatRelativeTime";
import { FormatTime } from "@/components/elements/FormatTime";
import IntegrationCellSyncFrequency from "@/components/elements/IntegrationCellSyncFrequency";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import cronExpressionToDayjs from "@/helpers/cronExpressionToDayjs";
import { useInterval } from "@/hooks/useInterval";

import { RowData } from "../columns";

function useNextSyncStatus(syncId: string, job: RowData["job"]) {
  const getStatus = (job: RowData["job"]) => {
    if (!job || job.status !== "STARTED") {
      return {
        isRunning: false,
        isStarting: false,
      };
    }
    const isRunning = job.running;
    let isStarting = false;
    if (job.nextRun) {
      const nextRun = dayjs(job.nextRun);
      isStarting = dayjs().isAfter(nextRun);
    }
    return {
      isRunning,
      isStarting,
    };
  };

  const shouldPoll = (job: RowData["job"]) => {
    if (!job || job.status !== "STARTED") return false;
    const jobStatus = getStatus(job);
    return jobStatus.isRunning || jobStatus.isStarting;
  };

  const jobStatus = getStatus(job);

  const { data, stopPolling } = useReverseEtlJobStatusQuery({
    fetchPolicy: "no-cache",
    variables: { syncId },
    pollInterval: shouldPoll(job) ? 5_000 : undefined,
    onCompleted(data) {
      if (data.findOneSync.job) {
        if (shouldPoll(data.findOneSync.job) === false) {
          stopPolling();
        }
      }
    },
    skip: !shouldPoll(job),
  });

  if (data?.findOneSync.job) {
    return getStatus(data.findOneSync.job);
  }
  return jobStatus;
}

export function NextSyncCell({
  syncInterval,
  row,
}: {
  syncInterval?: string | null;
  row: RowData;
}) {
  useInterval(() => {}, 5000);

  const { isRunning, isStarting } = useNextSyncStatus(row.id, row.job);

  if (isRunning) {
    return <Badge variant={BadgeVariant.Default}>Running</Badge>;
  }
  if (isStarting) {
    return <Badge variant={BadgeVariant.DefaultSubtle}>Starting...</Badge>;
  }

  if (row.status === "NOT_STARTED" || row.status === "PAUSED") {
    return <TextMuted>-</TextMuted>;
  }

  const cronExpression = row.orchestrationWorkflow?.id
    ? row.orchestrationWorkflow.cronExpression
    : syncInterval;

  if (cronExpression) {
    const date = cronExpressionToDayjs(cronExpression);
    return (
      <>
        <Tooltip content={<FormatTime date={date} format="LLL" />}>
          <FormatRelativeTime date={date} className="mb-1 block" />
        </Tooltip>
        <TextMuted
          as="div"
          className="hidden whitespace-nowrap text-xs leading-none md:block"
        >
          <CronDisplayReadable cron={cronExpression} />
        </TextMuted>
      </>
    );
  }
  if (row.orchestrationWorkflow?.id)
    return (
      <IntegrationCellSyncFrequency
        syncInterval={row.orchestrationWorkflow.cronExpression}
      />
    );
  if (syncInterval) {
    return <IntegrationCellSyncFrequency syncInterval={syncInterval} />;
  }
  return null;
}
