import { FormProvider, SubmitHandler } from "react-hook-form";

import { InvitationsDocument, useSendInvitationMutation } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { useToast } from "@/providers/ToastProvider";
import { ComponentProps } from "@tw-classed/react";

import {
  InvitationForm,
  InvitationFormModel,
  useInvitationForm,
} from "./InvitationForm";

export function NewInvitationDialog(props: ComponentProps<typeof Modal>) {
  const toast = useToast();

  const form = useInvitationForm();
  const { isSubmitting, isValid } = form.formState;

  const [sendInvitation] = useSendInvitationMutation({
    onError(error) {
      toast("Invite not sent", error.message, "error");
    },
    refetchQueries: [{ query: InvitationsDocument }],
    awaitRefetchQueries: true,
    onCompleted() {
      toast("Invite sent", undefined, "success");
      form.reset();
      props.onClose();
    },
  });

  const onSubmit: SubmitHandler<InvitationFormModel> = async (values) => {
    await sendInvitation({
      variables: {
        email: values.email.replace(/\s/g, "").toLowerCase(),
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        role: values.role.value,
      },
    });
  };

  return (
    <Modal {...props}>
      <ModalHeader>New Invitation</ModalHeader>
      <ModalCloseButton />
      <FormProvider {...form}>
        <form id="invitation-form" onSubmit={form.handleSubmit(onSubmit)}>
          <ModalBody>
            <InvitationForm />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              disabled={isSubmitting || !isValid}
              isLoading={isSubmitting}
              loadingText="Submitting..."
              variant="solid"
              colorScheme="primary"
            >
              Send invitation
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
}
