import { useInvitationsSuspenseQuery } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { EmptyState } from "@/components/elements/EmptyState";
import { useUsersLimitReachedUpgradeDialog } from "@/features/subscription";
import { useDisclosure } from "@/hooks/useDisclosure";
import { PlusIcon } from "@heroicons/react/20/solid";

import InvitationTable from "./InvitationsTable";
import { NewInvitationDialog } from "./NewInvitationDialog";

export function InvitationsPanel() {
  const { data } = useInvitationsSuspenseQuery();

  const { validateLimitReached, limitReachedDialog } =
    useUsersLimitReachedUpgradeDialog();

  const invitationDialogDisclosure = useDisclosure();
  if (data.invitations.length === 0) {
    return (
      <EmptyState
        header="No Pending Invitations Found"
        description="Invite new member to your account"
        action={
          <>
            <Button
              onClick={() => {
                validateLimitReached(() => {
                  invitationDialogDisclosure.onOpen();
                });
              }}
              icon={<PlusIcon />}
            >
              Invite new member
            </Button>
            <NewInvitationDialog {...invitationDialogDisclosure} />
            {limitReachedDialog()}
          </>
        }
      />
    );
  }
  return <InvitationTable data={data.invitations} />;
}
