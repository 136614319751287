import { ComponentProps, Suspense } from "react";

import { useSidebar } from "@/components/elements/Sidebar";
import cn from "@/helpers/classNames";
import { useMountEffect } from "@/hooks/useMountEffect";
import { Outlet } from "@tanstack/react-location";

import { SettingsNav } from "./SettingsNav";

function ContentWrapper(props: ComponentProps<"div">) {
  return (
    <div
      className={cn("mx-auto w-full max-w-8xl px-4 lg:px-8", props.className)}
    >
      {props.children}
    </div>
  );
}

export function SettingsLayout() {
  const { toggleCollapseState } = useSidebar();
  useMountEffect(() => toggleCollapseState(true));
  return (
    <div className="overflow-y-auto overflow-x-hidden">
      <main className="h-full">
        <header className="border-b py-6">
          <ContentWrapper>
            <h1 className="text-2xl">Account Settings</h1>
          </ContentWrapper>
        </header>
        <ContentWrapper className="mt-8">
          <div className="flex flex-col gap-4 sm:flex-row lg:gap-8">
            <div className="mt-1 shrink-0 sm:w-48">
              <SettingsNav className="sticky top-4" />
            </div>
            <div className="min-w-0 grow">
              <Suspense fallback={<div>Loading...</div>}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </ContentWrapper>
      </main>
    </div>
  );
}
