import { LocationGenerics, getAccountContext } from "routes";

import { StripeSubscriptionDocument } from "@/apollo/types";
import { StripeProvider } from "@/features/billing";
import { backOfficeRoute } from "@/pages/BackOffice/routes";
import { connectionsRoute } from "@/pages/Connections/routes";
import { ApolloClient } from "@apollo/client";
import { Navigate, Route } from "@tanstack/react-location";

import AccountPage from "./Account/AccountPage";
import ApiKeysPage from "./ApiKeys/ApiKeysPage";
import AuditLogsPage from "./AuditLogs/AuditLogsPage";
import BIToolsPage from "./BITools/BIToolsPage";
import ExternalToolsPage from "./ExternalTools/ExternalToolsPage";
import { SettingsLayout } from "./Layout";
import MembersPage from "./Members/MembersPage";
import NotificationsPage from "./Notifications";

export const settingsRoute = (
  client: ApolloClient<object>,
): Route<LocationGenerics> => {
  return {
    path: "settings",
    meta: {
      breadcrumb: () => "Settings",
    },
    element: <SettingsLayout />,
    children: [
      {
        path: "account",
        element: <AccountPage />,
        meta: {
          breadcrumb: () => "Delete Account",
        },
      },
      {
        path: "bi-tools",
        element: <BIToolsPage />,
        meta: {
          breadcrumb: () => "BI Tools",
        },
      },
      {
        path: "external-tools",
        element: <ExternalToolsPage />,
        meta: {
          breadcrumb: () => "External Tools",
        },
      },
      {
        path: "data-warehouse",
        meta: {
          breadcrumb: () => "Data Warehouse",
        },
        element: () =>
          import("./DataWarehouse/DataWarehousePage").then((m) => (
            <m.default />
          )),
      },
      {
        path: "notifications",
        meta: {
          breadcrumb: () => "Notifications",
        },
        element: <NotificationsPage />,
      },
      {
        path: "audit-logs",
        meta: {
          breadcrumb: () => "Audit Logs",
        },
        element: <AuditLogsPage />,
      },
      {
        path: "api-keys",
        meta: {
          breadcrumb: () => "API Keys",
        },
        element: <ApiKeysPage />,
      },
      {
        path: "users",
        element: <Navigate to="/settings/members" />,
      },
      {
        path: "members",
        meta: {
          breadcrumb: () => "Members",
        },
        element: <MembersPage />,
      },
      {
        path: "user-profile",
        meta: {
          breadcrumb: () => "User Settings",
        },
        element: () =>
          import("./user/UserSettingsPage").then((m) => <m.UserSettingsPage />),
      },
      {
        path: "billing",
        meta: {
          breadcrumb: () => "Billing",
        },
        loader: async (match) => {
          return (
            await client.query({
              query: StripeSubscriptionDocument,
              variables: { slug: match.params.slug },
              context: await getAccountContext(match.params.slug, client),
            })
          ).data;
        },
        children: [
          {
            path: "modify-plan",
            meta: {
              breadcrumb: () => "Modify Plan",
            },
            element: () =>
              import("./Billing/ModifyPlanPage").then((m) => <m.default />),
          },
          {
            element: () =>
              import("./Billing/BillingPage").then((m) => (
                <StripeProvider>
                  <m.default />
                </StripeProvider>
              )),
          },
        ],
      },
      connectionsRoute(client),
      backOfficeRoute,
      {
        element: <Navigate to={`./connections`} />,
      },
    ],
  };
};
