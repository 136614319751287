import { BACKEND_URL } from "constants/index";
import { useState } from "react";
import { CellProps, Column } from "react-table";

import { Role, useFindAllAvailableAuditLogsQuery } from "@/apollo/types";
import { UtilityButton } from "@/components/elements/Button";
import DefaultTable from "@/components/elements/DefaultTable";
import useWeldAPI from "@/hooks/useWeldAPI";
import { useToast } from "@/providers/ToastProvider";
import { useUserRole } from "@/providers/account";

import { SettingsPageLayout } from "../PageLayout";
import { AuditLogsFeatureGuard } from "./components/AuditLogsFeatureGuard";
import { AuditLogsTeaser } from "./components/AuditLogsTeaser";

type Row = { date: string; count: number; type: string };

export default function AuditLogsPage() {
  const userRole = useUserRole();
  const toast = useToast();
  const { data, loading } = useFindAllAvailableAuditLogsQuery();
  const fetchAPI = useWeldAPI();
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const tableData = data?.findAllAvailableAuditLogs.logs || [];

  if (userRole !== Role.Admin) {
    return (
      <SettingsPageLayout title="Audit Logs">
        <>You don't have access to see Audit Logs.</>
      </SettingsPageLayout>
    );
  }

  async function downloadCsvFile(date: string, type: string) {
    if (isLoadingFile) return;
    try {
      setIsLoadingFile(true);

      const response = await fetchAPI(`/download/csv/audit-logs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date, type }),
      });

      if (!response.ok) {
        const res = await response.json();
        throw new Error(res.error ?? "Error downloading csv file");
      }

      const { uuid } = await response.json();

      const link = document.createElement("a");
      link.href = `${BACKEND_URL}/download/csv/${uuid}`;
      link.setAttribute("download", "");

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      setIsLoadingFile(false);
    } catch (error: any) {
      toast("Error while downloading CSV", error.message, "error");
      setIsLoadingFile(false);
      return;
    }
  }

  const columns: Column<Row>[] = [
    {
      Header: "Date",
      Cell: ({ row }: CellProps<Row>) => {
        return <span>{row.original.date}</span>;
      },
      accessor: "date",
    },
    {
      Header: "Count",
      Cell: ({ row }: CellProps<Row>) => {
        return <span>{row.original.count}</span>;
      },
      accessor: "count",
    },
    {
      Header: "Download",
      Cell: ({ row }: CellProps<Row>) => {
        return (
          <UtilityButton
            onClick={() =>
              downloadCsvFile(row.original.date, row.original.type)
            }
          >
            Download
          </UtilityButton>
        );
      },
    },
  ];

  return (
    <SettingsPageLayout title="Audit Logs">
      <AuditLogsFeatureGuard
        fallback={() => (
          <div className="mx-auto w-full max-w-lg rounded border border-gray-200 p-8 dark:border-gray-700">
            <AuditLogsTeaser />
          </div>
        )}
      >
        <DefaultTable
          columns={columns}
          data={tableData}
          isLoading={loading}
          paginate={true}
        />
      </AuditLogsFeatureGuard>
    </SettingsPageLayout>
  );
}
