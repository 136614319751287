import React, { ComponentProps } from "react";

import { PlanName } from "@/apollo/types";
import { Heading, TextMuted } from "@/components/elements/Typography";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import { formatPlanName } from "@/features/billing";
import cn from "@/helpers/classNames";

export function AuditLogsTeaser(props: ComponentProps<"div">) {
  return (
    <div {...props} className={cn("flex flex-col gap-4", props.className)}>
      <Heading className="text-center text-xl">
        Enhance your security with Audit Logs
      </Heading>
      <TextMuted className="text-pretty text-sm leading-snug">
        Audit Logs provide detailed activity records for your account, enhancing
        security and accountability. They track user actions, changes, and other
        events, helping you maintain a secure and transparent environment.
      </TextMuted>
      <div className="flex w-full flex-col gap-4 text-center">
        <span>
          Audit Logs are available on the{" "}
          <strong>{formatPlanName(PlanName.Business)}</strong> plan.
        </span>
        <div>
          <UpgradeNowButton source="feature guard - audit logs" />
        </div>
      </div>
    </div>
  );
}
