import { ReactNode } from "react";

import { Heading } from "@/components/elements/Typography";

export function SettingsPageLayout(
  props: {
    children: ReactNode;
    action?: ReactNode;
  } & (
    | { title: ReactNode; description?: string; renderHeader?: never }
    | { renderHeader: () => ReactNode; title?: never }
  ),
) {
  return (
    <div className="w-full">
      {props.renderHeader ? (
        <div className="mb-6">{props.renderHeader()}</div>
      ) : (
        <header className="mb-6 flex items-center gap-4">
          <div>
            <Heading as="h2" className="text-xl">
              {props.title}
            </Heading>
            {props.description && (
              <p className="mt-2 text-sm text-muted-foreground">
                {props.description}
              </p>
            )}
          </div>
          <div className="ml-auto min-h-10">{props.action && props.action}</div>
        </header>
      )}
      <div>{props.children}</div>
    </div>
  );
}
