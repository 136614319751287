import dayjs from "dayjs";
import React, { useState } from "react";
import { CellProps, Column } from "react-table";

import {
  ApiKeysDocument,
  ApiKeysQuery,
  useDeleteApiKeyMutation,
} from "@/apollo/types";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import DefaultTable from "@/components/elements/DefaultTable";
import MenuItem from "@/components/elements/MenuItem";
import Tooltip from "@/components/elements/Tooltip";
import TableMenu from "@/components/modules/TableMenu";
import { dateFormat, dateTimeFormat } from "@/helpers/dateFormat";
import useDeleteItem from "@/hooks/useDeleteItem";

import { ApiSecretKeyReveal } from "./ApiSecretKeyReveal";

type Data = ApiKeysQuery["apiKeys"];
type Row = Data[0];

export default function ApiKeysTable(props: { data: Data }) {
  const columns = React.useMemo<Column<Row>[]>(
    () => [
      {
        Header: "Name",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.name}</span>;
        },
      },
      {
        Header: "Value",
        Cell: ({ row }: CellProps<Row>) => {
          return <ApiSecretKeyReveal apiKeyId={row.original.id} />;
        },
        width: 300,
      },
      {
        Header: "Created",
        Cell: ({ row }: CellProps<Row>) => (
          <Tooltip
            content={dayjs(row.original.createdAt).format(dateTimeFormat)}
          >
            <div>{dayjs(row.original.createdAt).format(dateFormat)}</div>
          </Tooltip>
        ),
        width: 1,
      },
      {
        Header: "",
        Cell: ({ row }: CellProps<Row>) => (
          <div className="flex justify-end">
            <ApiKeyMenu apiKeyId={row.original.id} />
          </div>
        ),
        accessor: "id",
        width: 1,
      },
    ],
    [],
  );

  return <DefaultTable columns={columns} data={props.data || []} />;
}

const ApiKeyMenu = ({ apiKeyId }: { apiKeyId: string }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleDelete = useDeleteItem({
    title: "api key",
    variables: { apiKeyId: apiKeyId },
    mutation: useDeleteApiKeyMutation,
    refetchQueries: [{ query: ApiKeysDocument }],
  });
  return (
    <>
      <ConfirmDeleteModal
        title="API key"
        onConfirm={handleDelete}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
      <TableMenu>
        <MenuItem text={"Delete"} onClick={() => setShowConfirmModal(true)} />
      </TableMenu>
    </>
  );
};
