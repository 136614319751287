import { SettingsPageLayout } from "../PageLayout";
import NotificationPreferences from "./NotificationPreferences";

export function NotificationsPage() {
  return (
    <SettingsPageLayout
      title="Notifications"
      description="Manage your notification preferences"
    >
      <NotificationPreferences />
    </SettingsPageLayout>
  );
}
