import { Button } from "components/elements/Button";
import { useDisclosure } from "hooks/useDisclosure";

import { Heading } from "@/components/elements/Typography";
import { DeleteAccountDialog } from "@/features/delete-account";

import { SettingsPageLayout } from "../PageLayout";

export default function AccountPage() {
  const disclosure = useDisclosure();
  return (
    <SettingsPageLayout title="Account">
      <div>
        <Heading className="mb-2">Delete Account</Heading>
        <p className="mb-4 text-sm text-muted-foreground">
          Permanently remove your account and all of its contents from the WELD
          platform. This action is not reversible — please continue with
          caution. Data in your warehouse will not be deleted.
        </p>
        <Button
          variant="outline"
          colorScheme="danger"
          onClick={() => disclosure.onOpen()}
        >
          Delete this account
        </Button>
        <DeleteAccountDialog {...disclosure} />
      </div>
    </SettingsPageLayout>
  );
}
