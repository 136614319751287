import { Suspense } from "react";

import { useInvitationsQuery } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { WeldTab } from "@/components/elements/tabs/Tab";
import { UsersLimitReachedAlert } from "@/components/modules/PlanAlerts/UsersLimitReachedAlert";
import { useUsersLimitReachedUpgradeDialog } from "@/features/subscription";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Tab } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";

import { SettingsPageLayout } from "../PageLayout";
import { InvitationsPanel } from "./InvitationsPanel";
import { NewInvitationDialog } from "./NewInvitationDialog";
import UsersTable from "./UsersTable";

export default function MembersPage() {
  const { validateLimitReached, limitReachedDialog, validating } =
    useUsersLimitReachedUpgradeDialog();

  const { data: invitationsData } = useInvitationsQuery();
  const numInvitations = invitationsData?.invitations.length;

  const invitationDialogDisclosure = useDisclosure();
  return (
    <SettingsPageLayout
      title="Members"
      action={
        <>
          <Button
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              validateLimitReached(() => {
                invitationDialogDisclosure.onOpen();
              });
            }}
            icon={<PlusIcon className="w-4" />}
            variant="solid"
            colorScheme="primary"
            isLoading={validating}
            loadingText="Invite member"
          >
            Invite member
          </Button>
          <NewInvitationDialog {...invitationDialogDisclosure} />
          {limitReachedDialog()}
        </>
      }
    >
      <Tab.Group as="div" className="flex h-full flex-col">
        <Tab.List className="mb-6 flex gap-8">
          <WeldTab>Members</WeldTab>
          <WeldTab>
            Invitations
            {typeof numInvitations === "number" && numInvitations > 0
              ? ` (${numInvitations})`
              : ""}
          </WeldTab>
        </Tab.List>

        <Tab.Panels className="grow">
          <Tab.Panel className="h-full">
            <UsersTable />
          </Tab.Panel>
          <Tab.Panel className="h-full">
            <UsersLimitReachedAlert className="mb-2" />
            <Suspense fallback={<LoadingSpinner />}>
              <InvitationsPanel />
            </Suspense>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </SettingsPageLayout>
  );
}
