import { useMemo, useState } from "react";

import { ActionButton, Button } from "@/components/elements/Button";
import { ButtonCard } from "@/components/elements/ButtonCard";
import { EmptyState } from "@/components/elements/EmptyState";
import FormInfoBox from "@/components/elements/FormInfoBox";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverFooter,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import Center from "@/components/elements/layout/Center";
import classNames from "@/helpers/classNames";
import { IntegrationLogo, IntegrationLogoBox } from "@/integrations";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import {
  ArrowUpOnSquareIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { SettingsPageLayout } from "../PageLayout";

export default function BIToolsPage() {
  return (
    <SettingsPageLayout title="BI Tools">
      <BIToolsInfo />
    </SettingsPageLayout>
  );
}

const BIToolsInfo = () => {
  const [showBITools, setShowBITools] = useState(false);
  return (
    <>
      <EmptyState
        header="Analyse and visualize Your data"
        description="Connect any BI tool directly to your Data Warehouse to start visualizing your data from WELD."
        action={
          <Button
            onClick={() => {
              setShowBITools(true);
            }}
            colorScheme="primary"
            variant="solid"
          >
            Connect BI guide
          </Button>
        }
      />
      <BIToolsSlideover
        isOpen={showBITools}
        onClose={() => {
          setShowBITools(false);
        }}
      />
    </>
  );
};

const BIToolsSlideover = (props: { isOpen: boolean; onClose: () => void }) => {
  const [selectedBITool, setSelectedBITool] = useState<(typeof BITools)[0]>();
  const [showBIToolDetails, setShowBIToolDetails] = useState(false);
  const [searchText, setSearchText] = useState("");

  const filteredBITools = useMemo(() => {
    return BITools.filter((biTool) => {
      return (
        biTool.name.toLowerCase().includes(searchText.toLowerCase()) ||
        biTool.description.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }, [searchText]);

  return (
    <>
      <SlideOver
        size="lg"
        show={props.isOpen}
        onClose={() => {
          props.onClose();
        }}
        bgOverlay
      >
        <SlideOverCloseButton />
        <SlideOverHeader className="box-content flex basis-11 items-center pb-6">
          <div className="flex gap-3">
            <Center>
              <div>
                <h3 className="text-xs text-gray-700 dark:text-gray-500">
                  Connect BI Tool
                </h3>
                <p>{"Available BI tools"}</p>
              </div>
            </Center>
          </div>
        </SlideOverHeader>
        <SlideOverBody>
          <div className={"relative mb-4"}>
            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search BI tools..."
              className={classNames(
                "rounded-xs h-10 border border-gray-200 pl-10 pr-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200",
              )}
            />
            <Center className="absolute left-0 top-0 h-full w-10">
              <MagnifyingGlassIcon className="h-4 text-gray-500" />
            </Center>
          </div>
          <div className="grid grid-cols-2 gap-8">
            {filteredBITools.map((biTool) => {
              return (
                <ButtonCard
                  key={biTool.integrationId}
                  className={classNames(
                    "group w-full items-center justify-start gap-5 rounded-sm p-5 text-left",
                  )}
                  onClick={() => {
                    setSelectedBITool(biTool);
                    setShowBIToolDetails(true);
                  }}
                >
                  <IntegrationLogoBox id={biTool.integrationId} size="md" />
                  <div className="text-lg font-medium">{biTool.name}</div>
                </ButtonCard>
              );
            })}
          </div>
        </SlideOverBody>
        <SlideOverFooter>
          <ActionButton
            variant="outline"
            colorScheme="secondary"
            onClick={() => {
              props.onClose();
            }}
          >
            Close
          </ActionButton>
        </SlideOverFooter>
      </SlideOver>

      <Modal
        isOpen={showBIToolDetails}
        onClose={() => {
          setShowBIToolDetails(false);
        }}
        size="lg"
      >
        <ModalCloseButton />
        {selectedBITool && <BIToolsDetailsView biTool={selectedBITool} />}
      </Modal>
    </>
  );
};

const BITools = [
  {
    integrationId: "metabase",
    name: "Metabase",
    description:
      "Metabase is an open-source BI tool for easy data exploration and visualization, enabling users to create interactive dashboards and reports. It supports various data sources, offers collaboration features, and is backed by an active community.",
    url: "https://www.metabase.com/",
    guideUrlBQ: "https://weld.app/docs/bi-tools/metabase#big-query",
    guideUrlSnowflake: "https://weld.app/docs/bi-tools/metabase#snowflake",
  },
  {
    integrationId: "powerbi",
    name: "PowerBI",
    description:
      "Power BI is a business intelligence tool by Microsoft that allows users to explore and analyze data through interactive dashboards and reports. It offers extensive data source connectivity, collaboration features, and advanced visualizations, making it a powerful tool for data-driven decision-making.",
    url: "https://powerbi.microsoft.com/en-us/",
    guideUrlBQ: "https://weld.app/docs/bi-tools/power-bi#big-query",
    guideUrlSnowflake: "https://weld.app/docs/bi-tools/power-bi#snowflake",
  },
  {
    integrationId: "tableau",
    name: "Tableau",
    description:
      "Tableau is a leading data visualization and analytics tool that empowers users to explore and understand their data through intuitive dashboards and interactive visualizations. With a wide range of data connectivity options, collaboration features, and advanced analytics capabilities",
    url: "https://www.tableau.com/",
    guideUrlBQ: "https://weld.app/docs/bi-tools/tableau#big-query",
    guideUrlSnowflake: "https://weld.app/docs/bi-tools/tableau#snowflake",
  },
  {
    integrationId: "looker",
    name: "Looker",
    description:
      "Looker is a powerful data exploration and analytics platform that allows users to gain insights from their data through intuitive dashboards, reports, and explorations. With its robust data modeling and querying capabilities, Looker enables users to easily access and analyze data from various sources. The platform promotes collaboration and offers advanced features such as embedded analytics and data sharing.",
    url: "https://looker.com/",
    guideUrlBQ: "https://weld.app/docs/bi-tools/looker#big-query",
    guideUrlSnowflake: "https://weld.app/docs/bi-tools/looker#snowflake",
  },
];

const BIToolsDetailsView = (props: { biTool: (typeof BITools)[0] }) => {
  const dwh = useDataWarehouseContext();

  return (
    <>
      <ModalHeader className="flex items-center gap-5">
        <IntegrationLogoBox id={props.biTool.integrationId} size="md" />
        <h1 className="text-lg">Connect to {props.biTool.name}</h1>
      </ModalHeader>
      <ModalBody className="pb-6">
        <p className="mb-6 text-sm">{props.biTool.description}</p>

        <FormInfoBox
          headline={`How ${props.biTool.name} works with WELD`}
          icon={<IntegrationLogo className="h-5 w-5" id={dwh.integration.id} />}
        >
          <p>
            {`All data from WELD is stored in your own ${dwh.integration.name}. By connecting ${props.biTool.name} directly to your data warehouse, you can get access to all your data created with WELD.`}
          </p>
        </FormInfoBox>

        <a
          href={props.biTool.guideUrlBQ}
          target="_blank"
          rel="noreferrer"
          className="relative mt-4 inline-flex h-8 min-w-[32px] shrink-0 items-center justify-center space-x-1 self-center rounded-sm border border-gray-200 bg-gray-100/0 px-3 text-center text-xs font-medium text-gray-700 shadow-sm outline-none ring-offset-white transition-colors focus-within:ring-2 hover:bg-gray-100/100 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:border-gray-700 dark:bg-gray-700/0 dark:text-gray-100 dark:ring-offset-gray-900 dark:hover:bg-gray-700/75"
        >
          <span>How to connect {props.biTool.name} guide</span>
          <ArrowUpOnSquareIcon className="h-4 w-4" />
        </a>
      </ModalBody>
    </>
  );
};
